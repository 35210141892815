import $ from 'jquery';

(function () {

  const SMOOTH_SCROLL_ATTR = 'data-smooth-scroll';

  $(document).on('click', `[${SMOOTH_SCROLL_ATTR}]`, function (e) {
    const $currentTarget = $(e.currentTarget);
    const $href = $currentTarget.attr('href');
    var formType = "door";

    var offsetTop = 0;
    if ($href != "#top") {
      var offsetTop = $($href).offset().top;
      if (!offsetTop) return;
    }

    if ($href == "#so-gehts") { offsetTop = offsetTop - 20; }
    else if ($href == "#teilnehmen") { offsetTop = offsetTop - 20; }
    else if ($href == "#gewinne") { offsetTop = offsetTop - 20; }
    else if ($href == "#eggs") { offsetTop = offsetTop + 80; }

    e.preventDefault();
    let duration = parseInt($currentTarget.attr(SMOOTH_SCROLL_ATTR)) || 1000;

    duration = parseInt($currentTarget.attr("data-duration")) || duration;

    $(this).attr('data-stop', offsetTop);
    $(this).attr('data-start', $(window).scrollTop());
    $(window).scrollTop(offsetTop);
    $(this).css('perc', 0).animate({ perc: 1 }, {
      duration: duration, step: function (fx, p) {
        var newv = Number($(this).attr('data-start')) - fx * (Number($(this).attr('data-start')) - Number($(this).attr('data-stop')));
        $(window).scrollTop(newv);
      }
    });
  });

})();

import "core-js/stable"
import "regenerator-runtime/runtime";

import $ from 'jquery';
(<any>window).jQuery = $;

import { Captcha } from './captcha';
new Captcha('[data-captcha-container]', '/captcha');

import { Validator } from './validator';
new Validator();

// Swiper Manager
import { SwiperManager } from './swiper-manager';
new SwiperManager();

import './smooth-scroll';
import './auto-send';

// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

$(function () {
    $("#hasEndedModal").modal("show");
    $("#hasNotStartedModal").modal("show");

    if ('ontouchstart' in window) {
        $("BODY").addClass("istouchdevice");
      }

    startAnimation();

    $(".mainsponsors").on("click",function(e){
        e.preventDefault();
        $(".section-sponsors A").trigger("click");
    })

    $(".appinfobtn").on("click",function(e){
        e.preventDefault();
        $("#appinfoModal").modal("show");
    })
});

function startAnimation()
{
    var widthoftile = $($(".section-sponsors .holder .pack")[0]).outerWidth();
    $(".section-sponsors .holder").css("left",0).animate(
        {left:-widthoftile},
        {duration:widthoftile*20,easing:"linear",complete:function(){
            startAnimation();
        }}
    );

    $("MAIN").css("min-height", $(window).innerHeight() - $("HEADER").outerHeight() - $("FOOTER").outerHeight() );

    $('.modal').on('shown.bs.modal', function () {
        $(this).find(".modal-close-x").on("click", function (e) {
            e.preventDefault();
            $(this).closest(".modal").modal("hide");
        });
        $(this).find(".btn-close").on("click", function (e) {
            e.preventDefault();
            $(this).closest(".modal").modal("hide");
        });
    })
}
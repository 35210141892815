import $ from 'jquery';

// // Swiper
import Swiper from 'swiper'; 
import { Navigation, Autoplay } from 'swiper/modules';

// import Swiper from 'swiper/swiper-bundle';

export class SwiperManager {

  constructor() {
    window["swiperManager"] = this;

    $(document).ready(() => {
      window["swiperManager"].initSwiperPrizes();
    });

  }

  initSwiperPrizes() {
    const swiperPrizes = new Swiper('.swiper-big', {
      modules: [Autoplay, Navigation],
      slidesPerView: "auto",
      spaceBetween: 10,
      speed: 2000,
      autoHeight: true,
      centeredSlides: true,
      centerInsufficientSlides: true,
      centeredSlidesBounds: true,
      grabCursor: true,
      autoplay: {
        delay: 500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      loop: true,
      // loopedSlides: 1,
      navigation: {
        nextEl: '.swiper-button-next-pr',
        prevEl: '.swiper-button-prev-pr',
      },
      breakpoints: {
        320: {
          spaceBetween: 10,
          speed: 1000,
          autoplay: {
            delay: 1000,
          }
        },
        480: {
          spaceBetween: 20,
          speed: 1500,
          autoplay: {
            delay: 750,
          }
        },
        1025: {
          spaceBetween: 30,
        },
      }
    });

    /*if ($(window).innerWidth() < 480 || window["isTouchDevice"])
      swiperPrizes.autoplay.stop();*/
  }
}

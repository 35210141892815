import $ from 'jquery';

$(() => {

  const FALLBACK_PATH = '/confirmed?error=notFoundOrExpired';

  $('[data-auto-send]').each((index: number, formElem: HTMLFormElement) => {

    const $formElement = $(formElem);
    const timeout = parseInt($formElement.data('autoSend') ?? 1200);

    const responseHandler = (data: any) => {
      data.redirectUrl = data.redirectUrl || FALLBACK_PATH;
      window.location.replace(data.redirectUrl);
    };

    const errorResponseHandler = (data: any) => {
      window.location.replace(FALLBACK_PATH);
    };

    setTimeout(() => {

      const fd = new FormData(formElem);

      $.ajax({
        url: $formElement.attr('action'),
        data: fd,
        method: $formElement.attr("method"),
        type: $formElement.attr("method"),
        dataType: "JSON",
        contentType: false,
        processData: false,

        success: responseHandler,
        error: (error) => errorResponseHandler(error.responseJSON)
      });

    }, timeout);

  });

});


import $ from 'jquery';

/**
 * Validation of the form and sending the data via AJAX.
 * The result will open a corresponding modal, depending on the servers response.
 */
export class Validator {

  private charpool: string = '<>|"§$%=?´`*+~#;:^°\\';

  constructor() {
    $(() => {
      if ($('#participation-form').length > 0) {

        let $submitBtn = $('#participation-form BUTTON[type=submit]');
        if ($submitBtn.hasClass('disabled')) {
          $submitBtn.click(function (e) {
            e.preventDefault();
            return false;
          });
          return
        }

        this.addListeners();
        this.checkAll();
      }
    })

  }

  inputHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Bitte fülle dieses Feld aus!';

    input.setCustomValidity(val.toString().length > 0 ? '' : textOutout);
    $(input).attr("title", val.toString().length > 0 ? '' : textOutout);

    if( val.toString().length > 0 && !this.filterSpecialchars(val.toString()) ){
      input.setCustomValidity('Dieses Feld enthält nicht erlaubte Zeichen (' + this.charpool + ').');
      $(input).attr("title", 'Dieses Feld enthält nicht erlaubte Zeichen (' + this.charpool + ').');
    }
  }

  filterSpecialchars(source: String) {
    for (var i = 0; i < this.charpool.length; i++) {
      if (source.indexOf(this.charpool[i]) > -1)
        return false;
    }
    return true;
  }

  emailSyncHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Die E-Mail-Adressen müssen übereinstimmen!';

    var validity = $('#email').val().toString() === val.toString() ? '' : textOutout;

    input.setCustomValidity(validity);
    $(input).attr("title", validity);
  }

  inputCodeHandler = (e: JQuery.EventBase): void => {
    const input = e.currentTarget as HTMLInputElement;
    const $input = $(input);
    const val = $input.val();

    var textOutout = 'Bitte gib einen 10-stelligen Code ein!';

    if (val.toString().length !== 10) {
      input.setCustomValidity(textOutout);
      $(input).attr("title", textOutout);
    } else {
      input.setCustomValidity("");
      $(input).attr("title", "");
    }
  }

  checkSelectValidity = (e: JQuery.EventBase): void => {
    var textOutout = 'Bitte wähle einen Eintrag in der Liste aus!';

    (e.currentTarget as HTMLInputElement).setCustomValidity($(e.currentTarget).prop('selectedIndex') > 0 ? '' : textOutout);
  }

  selectOptionIsValid = ($obj: JQuery): void => {
    var textOutout = 'Bitte wähle einen Eintrag in der Liste aus!';

    if ($obj.length)
      ($obj[0] as HTMLInputElement).setCustomValidity($obj.prop('selectedIndex') > 0 ? '' : textOutout);
  }

  checkAll = () => {
    $('#participation-form').addClass('invalidform');

    if ($('#submitbtn').hasClass('disabled'))
      return false;

    $('input[type=text],select').trigger('change');
  }

  addListeners = () => {
    $('#code').on('input change', this.inputCodeHandler);
    $('#title').on('change', this.checkSelectValidity);
    $('#first_name').on('input change', this.inputHandler);
    $('#last_name').on('input change', this.inputHandler);
    $('#email').on('input change', this.inputHandler);
    $('#email2').on('input change', this.inputHandler);
    $('#email2').on('input change', this.emailSyncHandler);

    $('#participation-form BUTTON[type=submit]').on('click', this.submitButtonClickHandler);
    $('#participation-form').on('submit', this.submitParticipationHandler);
  }

  removeListeners = () => {
    $('#code').off('input change', this.inputCodeHandler);
    $('#title').off('change', this.checkSelectValidity);
    $('#first_name').off('input change', this.inputHandler);
    $('#last_name').off('input change', this.inputHandler);
    $('#email').off('input change', this.inputHandler);
    $('#email2').off('input change', this.inputHandler);
    $('#email2').off('input change', this.emailSyncHandler);

    $('#participation-form BUTTON[type=submit]').off('click', this.submitButtonClickHandler);
    $('#participation-form').off('submit', this.submitParticipationHandler);
  }

  submitPressed = false;

  submitButtonClickHandler = (e: JQuery.Event): void => {
    this.submitPressed = true;
    this.checkAll();
  }

  submitParticipationHandler = (e: JQuery.Event): boolean => {
    e.preventDefault();

    const form = (e as JQuery.EventBase).currentTarget;
    const $form = $(form);

    try {
      const fd = new FormData(form);

      const responseHandler = (data: any) => {
        if( !data ) return;
        if ( data.status === 500) {
          this.feedbackModal("Hoppla", $(`<div>Es ist ein technischer Fehler aufgetretet. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</div>`), 'Schließen', true);
        }else if (data.status === 400) {
          let errors = '';
          for (const key in data.details) {
            errors += `<li class="error-list-item">${data.details[key]}</li>`;
          }
          errors = `<div class="errors"><ul class="error-list">${errors}</ul></div>`;

          if( typeof data.details["captcha"] != "undefined" ){
            const html = `<div>Wir bitten um Prüfung und Korrektur.</div>`;
            this.feedbackModal("Zeichenfolge nicht korrekt", $(html), 'Zur Eingabe');
          }else{
            const html = data.messageText ? `<div>${data.messageText}</div>${errors}` : `${errors}`;
            this.feedbackModal(data.feedbackTitle, $(html), 'Schließen');
          }
        }else if (data.status === 409) {
          let errorTitle = ''; let errorMessage = ''; let btnLabel = 'Schließen'; let codeRefresh = false;

          if (data.code === 'PARTICIPATION_ERROR') {
            errorTitle = "Hoppla";
            errorMessage = `<div>Die maximale Anzahl an Teilnahmen in der letzten Stunde wurde leider überschritten. Weitere Teilnahmen sind in einer Stunde wieder möglich.</div>`;
          } else if (data.code === 'STORE_ERROR') {
            errorTitle = "Hoppla";
            errorMessage = `<div>Durch einen technischen Fehler konnte die Teilnahme nicht gespeichert werden. Sollte es bei einem weiteren Versuch nicht klappen? Dann einfach per E-Mail melden an: <a href="mailto:info@edeka-glueckslos.de">info@edeka-glueckslos.de</a>.</div>`;
          } else if (data.code === 'CODE_ALREADY_REQUESTED') {
            errorTitle = "Hoppla";
            errorMessage = `<div>Der Code wurde bereits verwendet.</div>`;
            btnLabel = 'Neuen Code eingeben';
            codeRefresh = true;
          } else if (data.code === 'CODE_INVALID') {
            errorTitle = "Hoppla";
            errorMessage = `<div>Der Code ist ungültig. Wir bitten um Prüfung der Eingabe.</div>`;
            btnLabel = 'Code erneut eingeben';
          }

          this.feedbackModal(errorTitle, $(errorMessage), btnLabel, codeRefresh);
        } else if (data.status === 200) {
          let errors = '';
          for (const key in data.details) {
            errors += `<li class="error-list-item">${data.details[key]}</li>`;
          }
          errors = `<div class="errors"><ul class="error-list">${errors}</ul></div>`;

          if( typeof data.details["captcha"] != "undefined" ){
            const html = `<div>Wir bitten um Prüfung und Korrektur.</div>`;
            this.feedbackModal("Zeichenfolge nicht korrekt", $(html), 'Zur Eingabe');
          }else{
            const html = data.messageText ? `<div>${data.messageText}</div>${errors}` : `${errors}`;
            this.feedbackModal(data.feedbackTitle, $(html), 'Schließen');
          }
        } else if (data.status === 'success') {
          this.confirmModal();
        }
      };

      $.ajax({
        url: $form.attr('action'),
        data: fd,
        method: $form.attr("method"),
        type: $form.attr("method"),
        dataType: "JSON",
        contentType: false,
        processData: false,

        success: responseHandler,
        error: (error) => responseHandler(error.responseJSON),
        complete: () => {
          $('[data-captcha-container]').trigger('refresh');
        }
      });
    } catch (err) {
      var errorOutput = 'Es ist ein Fehler aufgetreten';
      this.feedbackModal(errorOutput, $(err.toString()));
    }

    return false;
  };

  feedbackModal = (title: string = "", html: JQuery<HTMLElement>, btnLabel: string = "Schließen", codeRefresh: boolean = false): void => {
    let $modal = $('#formerrorModal');
    $modal.find('.modal-title').text(title);
    $modal.find('.messagetext').html('');
    $modal.find('.messagetext').append(html);
    $modal.find('.btn-close').html(btnLabel);
    if (codeRefresh) {
      $modal.find('.btn-close').one("click", function () {
        $("#code").val("");
      });
    }
    setTimeout(() => {
      $modal.modal('show');
    }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

  confirmModal = (): void => {
    let $modal = $('#formfeedbackModal');

    $modal.find('.btn-close').one("click", function () {
      $("#code").val("");
    });

    setTimeout(() => {
      $modal.modal('show');
    }, 0); // NOTE: the timeout is important to keep bootstraps class management on the body tag intact!
  }

}
